



















































import { mdiEye, mdiEyeOff } from '@mdi/js';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import LogoCard from '@/components/specific/LogoCard.vue';
import { HINT_PASSWORD_COMPLEX, MESSAGE_PASSWORD_RESET_DONE } from '@/resources/defines';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIPasswordResetMapper } from '@/store/modules/ui/passwordReset';

const AuthService = ServiceFactory.get('auth');

export default Vue.extend({
  name: 'PasswordResetPage',
  components: { LogoCard },

  data(): {
    hint: string;
    icons: {
      [key: string]: string;
    };
    loading: boolean;
    resetParams: {
      confirmation_code: string;
      new_password: string;
    };
    showedPassword: {
      new: boolean;
    };
  } {
    return {
      hint: HINT_PASSWORD_COMPLEX,
      icons: {
        mdiEye,
        mdiEyeOff,
      },
      loading: false,
      resetParams: {
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable @typescript-eslint/camelcase */
        confirmation_code: '',
        new_password: '',
        /* eslint-enable @typescript-eslint/camelcase */
      },
      showedPassword: {
        new: false,
      },
    };
  },

  computed: {
    ...UIPasswordResetMapper.mapState(['passwordResetMailAddress']),
  },

  methods: {
    ...UIPasswordResetMapper.mapActions(['clearPasswordResetMailAddress']),
    ...UICommonMapper.mapActions(['setMessage', 'setErrorMessage']),
    async setPassword() {
      const self = this;

      self.$v.$touch();

      if (self.$v.$invalid) {
        self.$$log.debug(self.$v);
        return;
      }

      self.loading = true;

      try {
        await AuthService.resetPassword({
          // APIによりパラメータ名が定義されているため除外
          /* eslint-disable @typescript-eslint/camelcase */
          confirmation_code: self.resetParams.confirmation_code,
          email: self.passwordResetMailAddress,
          new_password: self.resetParams.new_password,
          /* eslint-enable @typescript-eslint/camelcase */
        });

        self.setMessage({
          color: 'success',
          text: MESSAGE_PASSWORD_RESET_DONE,
        });

        self.loading = false;

        // パスワード再設定受付メールアドレスを消去
        self.clearPasswordResetMailAddress();

        self.$router.push('/login');
      } catch (error) {
        self.loading = false;
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }
    },
  },

  validations() {
    return {
      resetParams: {
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        confirmation_code: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          fixedLength: this.$$validators.fixedLength(6),
          /* eslint-enable vue/sort-keys */
        },
        // APIによりパラメータ名が定義されているため除外
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        new_password: {
          // エラーメッセージを表示したい順序で定義する
          /* eslint-disable vue/sort-keys */
          required,
          passwordComplexity: this.$$validators.passwordComplexity,
          /* eslint-enable vue/sort-keys */
        },
      },
    };
  },
});
